import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './elements/Header';
import Home from './Home';
import News from './News';
import Article from './Article';
import ScrollToTop from './elements/Scroll-to-top';
import NotFound from './Not-Found';
import { Auth0Provider } from '@auth0/auth0-react';
import Dashboard from './admin/Dashboard';
import AdminHeader from './admin/Admin-Header';
import AdminNews from './admin/Admin-News';
import Editor from './admin/Editor';
import Pricing from './Pricing';
import Contact from './Contact';
import MobileContact from './elements/mobile-contact';
import Mailbox from './admin/Mailbox';
import FullMail from './admin/Full-Mail';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
          domain="dev-m65c5ylxqmm3bqjb.us.auth0.com"
          clientId="SfcgtZF6gKZ7V7DqfPt7ZRMqToo5DEQh"
          authorizationParams={{
            redirect_uri: 'https://borisova-jurist.ru/admin',
            // redirect_uri: 'http://localhost:3000/admin',
          }}>
    <BrowserRouter>
      <ScrollToTop/>
      <Header/>
      <AdminHeader/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/pricing' element={<Pricing/>}/>
        <Route path='/news/:_id?' element={<News/>}/>
        <Route path='/articles/:title' element={<Article/>}/>
        <Route path='/admin' element={<Dashboard/>}/>
        <Route path='/admin/mailbox' element={<Mailbox/>}/>
        <Route path='/admin/mailbox/:_id' element={<FullMail/>}/>
        <Route path='/admin/news/:_id?' element={<AdminNews/>}/>
        <Route path='/admin/editor/:_id?' element={<Editor/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
      <MobileContact/>
    </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
