import axios from "axios";

// https://borisova-jurist-2-0-backend.onrender.com

export async function getHomePagePosts(setPosts) {
    await axios.get('https://borisova-jurist-2-0-backend.onrender.com/user')
    .then(({ data }) => {
        setPosts(data);
    })
}

export async function searchPosts(search, setPosts) {
    await axios.get(`https://borisova-jurist-2-0-backend.onrender.com/user/search/${search}`)
    .then(({ data }) => {
        console.log(data);
        setPosts(data);
    })
}

export async function getPosts(setPosts, pageNo) {
    await axios.get(`https://borisova-jurist-2-0-backend.onrender.com/user/news/${pageNo}`)
    .then(({ data }) => {
        setPosts(data);
    })
}

export async function getPost(setPost, _id) {
    await axios.get(`https://borisova-jurist-2-0-backend.onrender.com/user/news/articles/${_id}`)
    .then(({data}) => {
        setPost(data);
    })
}

export async function sendMail(email, setEmail, text, setText) {
    await axios.post('https://borisova-jurist-2-0-backend.onrender.com/user/send', {
        sender: email,
        text: text,
        read: false,
    })
    .then(({ data }) => {
        setEmail('');
        setText('');
    })
}