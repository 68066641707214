import { useLocation, useNavigate } from 'react-router-dom'
import logo from '../media/icon1.jpg'
import contact from '../media/social-media.png'
import { useState } from 'react';
import ContactMenu from './contact-menu';

export default function Header() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [numberClicked, setNumberClicked] = useState(false);
    const [isOpened, setOpening] = useState(false);

    if (numberClicked) {
        navigator.clipboard.writeText('+79185815310');
        setTimeout(() => {
            setNumberClicked(false);
        }, 2000);
    }

    return !pathname.includes('/admin') && <header>
        <button className='home' onClick={() => navigate('/')}>
            <img src={logo} alt='Логотип'/>
            <h1>Борисова Наталья <span className='not-for-mobile'>Александровна</span></h1>
        </button>
        <p className='phone-number not-for-mobile' onClick={() => setNumberClicked(true)}>{!numberClicked ? '+7 918 581 53 10' : 'Скопировано!'}</p>
        <button className='contact' onClick={() => setOpening(!isOpened)}>
            <img src={contact} alt='Контакты'/>
        </button>
        <div className='not-for-mobile'>
            <ContactMenu isShown={isOpened} setShowing={setOpening}/>
        </div>
    </header>
}