import { useState } from "react"
import { sendMail } from "../connect"

export default function EmailForm() {
    const [lid, setLid] = useState(false);
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');

    function handleSubmit() {
        if (text && email) {
            setLid(true);
            sendMail(email, setEmail, text, setText);
        }
    }

    return <section className="contact">
        <h3>Есть проблема?</h3>
        <p>Решение найдется быстро – стоит только написать</p>
        <form>
            <input required='required' type="email" placeholder="Ваша эл. почта" value={email} onChange={event => setEmail(event.target.value)}/>
            <textarea required='required' value={text} onChange={event => setText(event.target.value)}/>
            <button type="submit" onClick={event => {
                event.preventDefault();
                handleSubmit();
            }}>Отправить</button>
        </form>
        <div className={('bg') + (lid ? '' : ' closed')}>
            <h4>Готово!</h4>
            <p>Ожидайте ответа от Натальи</p>
        </div>
    </section>
}