import case1 from '../media/A53-13484-2021.pdf';
import case2 from '../media/A40-76453-2020.pdf';
import case3 from '../media/A53-13552-2020.pdf';
import case4 from '../media/A53-13564-2020.pdf';
import case5 from '../media/A53-17478-2020.pdf';
import case6 from '../media/A53-36995-2020.pdf';
import case7 from '../media/А53-46461-2023.pdf';
import case8 from '../media/А53-19433-5-2021.pdf';

export const results = [
    <>Вернули собственникам-предпринимателям объекты недвижимости на общую сумму <span>более 500 млн. рублей</span></>,
    <>За последние три года в судебном порядке сэкономили предпринимателям <span>более 35 млн. рублей</span></>,
    <>За последний год вернули <span>более 8 млн. рублей</span> по гражданским сделкам.</>,
    <><span>Создали прецеденты</span> в Северо-Кавказском округе при оспаривании актов государственной власти (Минимущества, Росимущества)</>
]

export const actualResults = [
    {
        name: 'А53-46461-2023',
        description: 'Отмена незаконного распоряжения Минимущества Ростовской области',
        file: case7,
    },
    {
        name: 'А53-19433-5-2021',
        description: 'Защита прав покупателя автомобиля при оспаривании сделки с банкротом',
        file: case8,
    },
    {
        name: 'A53-13484-2021',
        description: 'Обжаловано распоряжение Минимущества РО об объединении земельных участков.',
        file: case1,
    },
    {
        name: 'A40-76453-2020',
        asking: '4 071 947,15',
        took: '375 556.33',
        economy: '3 696 390,82',
        file: case2,
    },
    {
        name: 'A53-13552-2020',
        asking: '851 428,24',
        took: '6 023,89',
        economy: '845 404,35',
        file: case3,
    },
    {
        name: 'A53-13564-2020',
        asking: '7 416 008,89',
        took: '729 438,03',
        economy: '6 686 570,86',
        file: case4,
    },
    {
        name: 'A53-17478-2020',
        asking: '5 150 510,04',
        took: '750 716,26',
        economy: '4 399 793,78',
        file: case5,
    },
    {
        name: 'A53-36995-2020',
        description: 'О признании права собственности на места общего пользования в нежилом здании.',
        file: case6,
    }
]