import { useEffect, useState } from "react"
import { adminPost, adminPosts } from "./admin-connect";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../media/Loading";
import DateObject from "react-date-object";
import FullPost from "./Admin-Full-Post";
import { useAuth0 } from "@auth0/auth0-react";
import Entrance from "./Entrance";

export default function AdminNews() {
    const { isAuthenticated } = useAuth0();
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState();
    const [pageNo, setPageNo] = useState(1);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            setPosts([]);
            adminPosts(setPosts, pageNo);
        }
    }, [pageNo, isAuthenticated])

    useEffect(() => {
        if (params._id && isAuthenticated) {
            adminPost(setPost, params._id);
        }
    }, [params._id, isAuthenticated]);

    return isAuthenticated ? <main id="news">
        <button className="back" onClick={() => navigate('/admin')}>{'< Назад'}</button>
        <h1>Последние новости</h1>
        <div className="list">
            {
                posts.length ? posts.map(post => {
                    return <section className="post" key={post._id} onClick={() => navigate(`./${post._id}`)}>
                        <h3>{post.title}</h3>
                        <p>{post.text.length > 100 ? post.text.substr(0, 100)+'...' : post.text}</p>
                        <p className="date">{new DateObject(post.date).format('DD.MM.YYYY')}</p>
                    </section>
                }) : <Loading/>
            }
        </div>
        {
            !(pageNo === 1 && posts.length < 10) &&
            <div className="page-selector">
                <button onClick={() => {
                    if (pageNo > 1) {
                        setPageNo(pageNo - 1);
                    }
                }}>{ '<' }</button>
                <p>{ pageNo }</p>
                { posts.length >= 10 && <button onClick={() => setPageNo(pageNo + 1)}>{'>'}</button> }
            </div>
        }
        <FullPost post={post} setPost={setPost}/>
    </main> : <Entrance/>
}