import { useEffect, useState } from "react"
import { getMail } from "./admin-connect";
import { useNavigate } from "react-router-dom";
import Loading from "../media/Loading";
import Entrance from "./Entrance";
import { useAuth0 } from "@auth0/auth0-react";

export default function Mailbox() {
    const { isAuthenticated } = useAuth0();
    const [mailbox, setMailbox] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            getMail(setMailbox);
        }
    }, [isAuthenticated])

    return isAuthenticated ? <main id="mailbox">
        <button className="back" onClick={() => navigate('/admin')}>{'< Назад'}</button>
        {
            mailbox ? mailbox.map(mail => {
                return <section onClick={() => navigate(`/admin/mailbox/${mail._id}`)} className={("mail") + (mail.read ? '' : ' unread')} key={mail._id}>
                    <h4>{ mail.sender }</h4>
                    <p>{ mail.text.length > 30 ? mail.text.substr(0, 30) + '...' : mail.text }</p>
                </section>
            }) : <Loading/>
        }
    </main> : <Entrance/>
}