import citizens_bankruptcy from '../media/citizen_bankruptcy.JPG'
import legal_entities_bankruptcy from '../media/legal_entity_bankruptcy.JPG'
import cancellation_of_a_court_order from '../media/cancellation_of_a_court_order.JPG'
import free_bankruptcy from '../media/free_bankruptcy.JPG'
import cadastral_value_reduction from '../media/cadastral_value_reduction.jpeg'
import market_rent_decrease from '../media/market_rent_decrease.jpeg'
import land_disputes from '../media/land_disputes.jpg'
import legal_support_of_transactions from '../media/legal_support_of_transactions.jpg'
import business_support from '../media/business_support.jpeg'
import family_disputes from '../media/family_disputes.jpeg'

export const data = [
    {
        title: 'Банкротство граждан',
        picture: citizens_bankruptcy,
        text: <article>
            <section classNameName="main">
                <p>Основная цель банкротства граждан — освобождение от долгов перед кредиторами. Согласно п. 3 ст. 213.28 Закона о банкротстве после завершения расчётов с кредиторами гражданин, признанный банкротом, освобождается от дальнейшего исполнения требований кредиторов,   числе требований кредиторов, не заявленных при введении реструктуризации долгов гражданина или реализации имущества гражданина.</p>
            </section>
            <section classNameName="youll_learn">
                <h3>На консультации вы узнаете:</h3>
                <ol>
                    <li>Подходит ли  вам процедура банкротства.</li>
                    <li>Возможно ли освободить от долгов должника, который может работать, но не хочет?</li>
                    <li>Подлежит ли реализации ваше имущество или есть законный способ его сохранить.</li>
                    <li>Что будет с вашим официальным доходом.</li>
                    <li>Что будет с совместной собственностью супругов</li>
                    <li>Сколько будет стоить банкротство</li>
                    <li>Длительность процедуры, ее этапы.</li>
                </ol>
            </section>
        </article>,
    },
    {
        title: 'Банкротство юридических лиц и ИП',
        picture: legal_entities_bankruptcy,
        text: <article>
            <section className="main">
                <p>Банкротство данной категории лиц более сложная и длительная процедура. Иногда мы встречаем дела, которые длятся с 2013 года  и которые только подошли к стадии реализации имущества. Конечно, длительность и сложность данной процедуры  обуславливается множеством хозяйственных сделок, совершаемых в обычной коммерческой деятельности коммерческими организациями и индивидуальными предпринимателями.  Зачастую сами лица-должники  с целью сохранить остатки имущества совершают множество ошибок, которые ведут к последующему их оспариванию. В результате возрастает количество  кредиторов, которые не получили встречного предоставления, что является основанием как для подачи требования о признании должника банкротом, так и требований о включении в реестр кредиторов.</p>
            </section>
            <section className="youll_learn">
                <h3>Основными направлениями в данной сфере для нас являются:</h3>
                <ol>
                    <li>Установление требований кредитора в деле о банкротстве на всех стадиях банкротского дела (реестровые, текущие  и зареестровые требования).</li>
                    <li>Конкурсное оспаривание сделок при банкротстве.</li>
                    <li>
                        Основаниями для оспаривания  юридических  действий при банкротстве  это
                        <ul>
                            <li>оспаривание сделок во вред кредиторам (подозрительные сделки) по ст. 61.2 Закона о банкротстве.</li>
                            <li>оспаривание сделок с преимущественным удовлетворением.</li>
                            <li>оспаривание действий по  непринятию  наследства,  оспаривание сделок  по итогам торгов.</li>
                        </ul>
                    </li>
                </ol>
            </section>
        </article>,
    },
    {
        title: 'Отмена судебного приказа',
        picture: cancellation_of_a_court_order,
        tableOfContents: [
            {
                name: 'По каким требованиям и документам суды выносят приказы?',
                reference: 'first',
            },
            {
                name: 'Срок отмены судебного приказа',
                reference: 'second',
            },
            {
                name: 'Как отменить судебный приказ, если пропущен срок?',
                reference: 'third',
            },
            {
                name: 'Как отменить судебный приказ, если его не получал?',
                reference: 'fourth',
            },
            {
                name: 'Сроки отмены судебного приказа',
                reference: 'fifth',
            },
            {
                name: 'Поворот исполнения судебного приказа',
                reference: 'sixth'
            },
        ],
        text: <article>
            <section className="main">
                <h1>Поворот исполнения судебного приказа  (возврат взысканных приставами  денежных средств по судебному приказу)</h1>
                <h2 id='first'>1. По каким требованиям и документам суды выносят приказы?</h2>
                <p>Судебный приказ — это постановление, которое выносится судьей единолично на основании заявления о взыскании денежных сумм или об истребовании движимого имущества от должника. При этом  размер требований не должен превышать 500 тысяч рублей. Судебный приказ выносится на основании заявления взыскателя по требованиям, указанным в ст. 122 ГПК РФ, а именно:</p>
                <ul>
                    <li>из сделок, которые заверил нотариус или которые заключены в простой письменной форме;</li>
                    <li>по протесту векселя, совершенному нотариусом;</li>
                    <li>о взыскании алиментов на несовершеннолетних детей (если не требуется устанавливать отцовство, оспаривать отцовство (материнство) или привлекать других заинтересованных лиц);</li>
                    <li>о взыскании денежных средств, которые были начислены, но не выплачены работнику (в т.ч. компенсации за задержку выплаты);</li>
                    <li>о взыскании задолженности по оплате услуг ЖКХ, телефонной связи, а также долгов по обязательным платежам и членским взносам в товариществах собственников недвижимости и потребительских кооперативах.</li>
                </ul>
                <h2 id="second">2. Срок для отмены судебного приказа</h2>
                <p>Должник может отменить судебный приказ в течение 10 дней с момента получения судебного приказа путем направления в суд, вынесший решение, соответствующих возражений (ст. 128 ГПК РФ).</p>
                <h2 id="third">3. Как отменить судебный приказ, если пропущен срок?</h2>
                <p>В случае, если срок на подачу возражений пропущен, он может быть восстановлен при наличии уважительных причин, например, таких как:</p>
                <ul>
                    <li>отсутствие в месте жительства из-за болезни;</li>
                    <li>нахождение в командировке, отпуске;</li>
                    <li>переезд в другое место жительства;</li>
                    <li>нарушение правил доставки почтовой корреспонденции и по иным причинам.</li>
                </ul>
                <h2 id="fourth">4. Как отменить судебный приказ, если его не получал?</h2>
                <p>Для отмены судебного приказа, который не был получен, необходимо направить мировому судье, который вынес приказ, заявление о восстановлении процессуального срока для подачи возражений относительно исполнения судебного приказа. В ходатайстве можно сослаться на нарушение правил оказания услуг почтовой связи, смену места жительства и т.д. Обязательно нужно приложить доказательства уважительной причины вместе с ходатайством о восстановлении срока на подачу возражений. В противном случае мировой судья вернет обратно возражение.</p>
                <h2 id='fifth'>5. Сроки для отмены  судебного  приказа?</h2>
                <p>При поступлении в десятидневный срок возражений должника относительно исполнения судебного приказа судья отменяет судебный приказ.</p>
                <h2 id='sixth'>6. Поворот исполнения судебного приказа.</h2>
                <p>Вынесение судом определения об отмене судебного приказа не влечёт автоматическое возвращение списанных денег. Если пристав уже перечислил деньги взыскателю, необходим поворот исполнения судебного акта. Такая юридическая процедура возможна после отмены приказа.  Для этого необходимо подать в суд на имя того же судьи заявление о повороте судебного приказа.</p>
            </section>
        </article>,
    },
    {
        title: 'Бесплатное банкротство',
        picture: free_bankruptcy,
        text: <article>
            <section className="main">
                <p>Упрощенная процедура банкротства физического лица введена с 01.09.2020.</p>
                <h2>Кто сможет стать банкротом в упрощенном порядке?</h2>
                <p>Воспользоваться внесудебной процедурой банкротства смогут граждане:</p>
                <ul>
                    <li>с общим долгом от 50 до 500 тыс. руб.;</li>
                    <li>с завершенным в отношении них исполнительным производством по причине отсутствия имущества, на которое можно обратить взыскание.</li>
                </ul>
                <h2>ВАЖНО</h2>
                <p>Так называемому "внесудебному" порядку банкротства граждан обязательно должно предшествовать судебное разбирательство и исполнительное производство:</p>
                <ol>
                    <li>кредиторы гражданина-должника должны сначала обратиться в суд,</li>
                    <li>получить исполнительный лист и передать его судебным приставам,</li>
                    <li>последние должны вынести постановление о невозможности взыскания и завершить исполнительное производство по причине отсутствия имущественных активов.</li>
                </ol>
                <p>Таким образом, данную процедуру никак нельзя назвать быстрой.</p>
                <h1>Если кредиторы гражданина-должника не обращались в суд, либо исполнительное производство не завершено, то освободиться от долгов не получится!</h1>
                <p>Заявление о личном банкротстве нужно подавать в МФЦ. В МФЦ проверят  соответствует ли заявитель  обозначенным требованиям. При выявлении несоответствий заявление вернут.</p>
                <p>Если всё в порядке, то МФЦ в течение 4-х дней разместит информацию о возбуждении процедуры внесудебного банкротства в Едином федеральном реестре сведений о банкротстве (ЕФРСБ). Длиться она будет полгода.</p>
                <p>Если заявитель  в этот период приобрел имущество  в собственность или при другом существенном изменении своего имущественного положения должник обязан в течение 5-ти дней уведомить МФЦ и процедуру банкротства прекратят.</p>
                <h2>Последствия возбуждения упрощенной процедуры банкротства</h2>
                <ul>
                    <li>Вводится мораторий (приостановление) на удовлетворение требований кредиторов за некоторыми исключениями.</li>
                    <li>Заявитель не вправе бать новые займы, кредиты, выступать поручителем в период процедуры банкротства.</li>
                    <li>Прекращаются  начисляться  неустойки, штрафы, пени, а также проценты по всем обязательствам гражданина.</li>
                </ul>
                <h2>ВАЖНО!</h2>
                <p>Мораторий не распространяется на  следующие долги:</p>
                <ul>
                    <li>по уплате алиментов,</li>
                    <li>по возмещению вреда, причиненного жизни или здоровью, морального вреда,</li>
                    <li>по другим требованиям, тесно связанным с личностью должника.</li>
                </ul>
                <h1 style={{ textDecoration: 'underline' }}>На эти долги не распространяется мораторий и их не спишут по результатам процедуры банкротства, по ним придется платить и дальше.</h1>
                <p>По истечении полугода, если никакого имущества так и не будет найдено, гражданина признают банкротом и все долги, кроме упомянутых исключений, спишут.</p>
                <h2>Кому пригодится упрощенная процедура банкротства?</h2>
                <p>Возможность признать себя банкротом для физических лиц появилась в 2015 году. Сделать это можно было только через суд. С каждым годом возможность списать таким образом долги становилась всё более популярной.</p>
                <p>Но процедура судебного банкротства не всем доступна из-за своей дороговизны.  Это отнюдь не 300 руб. госпошлины и 25 тыс. руб. на услуги финансового управляющего. Включая сопутствующие и дополнительные расходы судебное банкротство обходится примерно в 110-120 тысяч рублей.</p>
                <p>Внесудебное банкротство - это бесплатно, поскольку не привлекается финансовый управляющий и нет дополнительных расходов. Вся процедура осуществляется через МФЦ.</p>
                <p>Тем самым банкротство стало доступным для граждан, оказавшихся в трудной жизненной ситуации, финансовой яме,  и не имеющих средств на судебную процедуру.</p>
                <hr style={{ opacity: 0, margin: '30px' }}/>
                <p>Если ваш случай не подходит под условия бесплатного банкротства, мы предложим наиболее оптимальный вариант судебного банкротства граждан.</p>
            </section>
        </article>,
    },
    {
        title: 'Уменьшение кадастровой стоимости',
        innerTitle: `Оспаривание кадастровой стоимости объекта недвижимости в ${new Date().getFullYear()} году`,
        picture: cadastral_value_reduction,
        text: <article>
            <section className="main">
                <p>В статье 378 Налогового кодекса Российской Федерации отражается полный перечень объектов недвижимости, по которым государство устанавливает кадастровую стоимость. В этот список входят:</p>
                <ul>
                    <li>Земельные участки</li>
                    <li>Административно-деловые и торговые центры</li>
                    <li>Нежилые помещения</li>
                    <li>Гаражи и машиноместа</li>
                    <li>Объекты незавершенного строительства</li>
                    <li>Жилые строения</li>
                    <li>Садовые дома</li>
                    <li>Хозяйственные строения или сооружения</li>
                </ul>
                <p>​Владельцы таких объектов могут пройти процедуру оспаривания кадастровой стоимости в случае несогласия с результатами государственной оценки (ГКО).</p>
                <p>Мы поможем определить является кадастровая стоимость Вашего объекта действительно завышенной.  Для этого мы определим  разницу между рыночной и кадастровой ценой. Для этого нужно  провести сравнительный анализ цен по региону и площади объекта.</p>
                <p>С 1 января 2023 года на территории Ростовской области установлен совершенно новый   порядок оспаривания кадастровой стоимости объектов недвижимости.</p>
                <p>Теперь  жители Дона для исправления ошибок, допущенных при определении кадастровой стоимости, или установления кадастровой стоимости объектов недвижимости в размере их рыночной стоимости  должны обратиться в  ГБУ РО «Центр содействия развитию имущественно-земельных отношений Ростовской области». Обратиться можно и в отношении результатов кадастровой оценки объектов недвижимости, проведенной еще в 2016 году.</p>
                <p>В случае же несогласия с решениями бюджетного учреждения по итогам рассмотрения указанных заявлений такие решения могут быть оспорены в Ростовском областном суде в порядке административного судопроизводства.</p>
                <p>Напомним, в  Ростове-на-Дону  результаты определения кадастровой стоимости земельных участков утверждены постановлением Министерства  имущественно-земельных отношений и финансового оздоровления  предприятий,  организаций Ростовской области и  применяются для целей, предусмотренных действующим законодательством с 1 января 2023 года.</p>
                <h1>Мы возьмем на себя все вопросы  уменьшения кадастровой стоимости на себя в кратчайшие для вас сроки.</h1>
            </section>
        </article>,
    },
    {
        title: 'Уменьшение рыночной аренды',
        innerTitle: 'Оспаривание размера арендной платы за земельные участки',
        picture: market_rent_decrease,
        text: <article>
            <section className="main">
                <p>Оспаривание размера арендной платы земельных участков, находящихся в государственной собственности (собственность РФ, собственность Ростовской области).</p>
                <p>В случае если земельный участок принадлежит Российской Федерации или Ростовской области, плата за пользование земельными участками для собственников зданий и сооружений на них  устанавливается  в соответствии с  Федеральным законом "Об оценочной деятельности в Российской Федерации". При этом для федеральной собственности данное правило распространяется практически на все земельные участки коммерческих организаций, для собственности субъекта РФ - Ростовской области  данное правило действует  на правоотношения, связанные с пользованием земельными участками до 01.10.2020 года.</p>
                <h1>Мы имеем огромный опыт уменьшения  платы за пользование земельными  участками в Ростовской области, г. Москве и Московской области.</h1>
                <h1>Нам удалось  снизить размер платы за пользование земельными участками от 40 до 70%.  </h1>
            </section>
        </article>,
    },
    {
        title: 'Земельные споры',
        picture: land_disputes,
        text: <article>
            <section className="main">
                <ol>
                    <li>Оспаривание кадастровой стоимости земельных участков.</li>
                    <li>Оспаривание размере арендной платы земельных участков.</li>
                    <li>Оформление земельных участков в собственность, аренду.  Льготный порядок предоставления земельных участков, находящихся в государственной федеральной собственности в собственность  граждан и юридических лиц.</li>
                    <li>Иные земельные споры.</li>
                </ol>
            </section>
        </article>,
    },
    {
        title: 'Юридическое сопровождение сделок',
        picture: legal_support_of_transactions,
        text: <article>
            <section className="main">
                <p>При осуществлении сделок с недвижимостью, покупатели всегда сталкиваются со сложностями  надлежащей проверки документов потенциального объекта покупки. Когда речь идет  о цене сделке, превышающей несколько десятков, а то и сотен  миллионов, мы готовы взять на себя ответственность  и провести полную экспертизу документов. От нас не ускользнет никакой «секрет», который мог бы повлиять как на стоимость недвижимости, так и  на саму сделку купли-продажи.</p>
                <p>В зависимости от сложности технических документов  и «возраста» объекта  поднимем всю правовую, архивную информацию, проверим все риски и Вы сможете принять правильное решение.</p>
                <h1>После нашей проверки ни один клиент не ушел недовольным.</h1>
            </section>
        </article>,
    },
    {
        title: 'Сопровождение бизнеса',
        picture: business_support,
        text: <article>
            <section className="main">
                <h1>Для вашей компании  предлагаем целый комплекс юридических услуг:</h1>
                <ul>
                    <li>устные и письменные консультации по правовым вопросам;</li>
                    <li>разработку и экспертизу договоров, приложений, дополнительных соглашений;</li>
                    <li>услуги по работе с контрагентами компании: составление претензий, ответов, взыскание дебиторской задолженности;</li>
                    <li>представительство в суде: подготовку правовой позиции, сбор и подготовку документов, процессуальные действия;</li>
                    <li>регистрацию компании, внесение изменений в учредительные документы.</li>
                </ul>
                <p>Имею огромный многолетний опыт сопровождения  компаний по всевозможным вопросам.</p>
                <p>Защищаю права и интересы  клиентов уже больше 20 лет – за это время наработана колоссальная практика, позволяющая быстро и эффективно справляться с любыми задачами.</p>
            </section>
        </article>,
    },
    {
        title: 'Семейные споры',
        picture: family_disputes,
        text: <article>
            <section className="main">
                <h2>Расторжение брака.</h2>
                <p>Расторжение брака всегда является болезненной процедурой обоих супругов. Чтобы избежать дополнительных стрессов и неприятных встреч  супругов, мы самостоятельно решим вопрос вашего расторжения брака.  Вам не придется никуда ходить, мы все сделаем за вас.</p>
                <h2>Раздел совместно нажитого имущества.</h2>
                <p>Это более сложная и более длительная  процедура. Мы сможем проанализировать ситуацию на начальном этапе, предложим варианты раздела,  поможем  разрешить данный вопрос во внесудебном порядке. При не достижении соглашения между бывшими супругами, защитим ваши интересы в суде с наилучшими для вас вариантами раздела.</p>
                <h2>Взыскание алиментов на несовершеннолетних детей.</h2>
                <p>Взыщем алименты как по судебному приказу так и в обычном судебном порядке.  Предложим наиболее оптимальные для вас варианты взыскания задолженностей по алиментам.</p>
                <h2>Общение с детьми.</h2>
                <p>Поможем в судебном порядке определить порядок общение с детьми, а также с  исполнением решения суда об определении порядка общения с детьми в принудительном порядке.</p>
            </section>
        </article>,
    },
]