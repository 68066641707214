import tel from '../media/telephone.png';
import email from '../media/email.png';
import WhatsApp from '../media/whatsapp.png';
import Telegram from '../media/telegram.png';
import more from '../media/more.png';
import { useNavigate } from 'react-router-dom';

export default function ContactMenu({ isShown, setShowing }) {
    const navigate = useNavigate();

    return <div id={"con-menu"} className={!isShown ? 'closed' : ''} onClick={() => setShowing(false)}>
        <button className='for-mobile' onClick={event => {
            event.preventDefault();
            window.location.replace('tel: +79185815310');
            }}>
            <img src={tel} alt='Телефон'/>
            <p>+7 (918) 581-53-10</p>
        </button>
        <button className='not-for-mobile' onClick={event => {
            event.preventDefault();
            window.location.replace('mailto:nat15@mail.ru');
            }}>
            <img src={email} alt='Эл. почта'/>
            <p>nat15@mail.ru</p>
        </button>
        <button onClick={() => window.open('https://wa.me/79185815310')}>
            <img src={WhatsApp} alt=''/>
            <p>WhatsApp</p>
        </button>
        <button onClick={() => window.open('https://t.me/borisovajurist')}>
            <img src={Telegram} alt=''/>
            <p>Канал</p>
        </button>
        <button onClick={() => navigate('/contact')}>
            <img src={more} alt=''/>
            <p>Еще</p>
        </button>
    </div>
}