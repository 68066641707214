import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom"

export default function Entrance() {
    const { loginWithRedirect, isLoading } = useAuth0();
    const navigate = useNavigate();

    return !isLoading && <main>
        <h1>Чтобы возпользоваться панелью управления, необходимо войти</h1>
        <button onClick={loginWithRedirect}>Войти</button>
        <button onClick={() => navigate('/')}>Вернуться на главную</button>
    </main>
}