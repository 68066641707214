import { useState } from 'react'
import contact from '../media/social-media.png'
import ContactMenu from './contact-menu'

export default function MobileContact() {
    const [isOpened, setOpening] = useState(false);

    return <div className="mob-con for-mobile">
        <button className="contact" onClick={() => setOpening(!isOpened)}>
            <img src={contact} alt='Контакты'/>
        </button>
        <ContactMenu isShown={isOpened} setShowing={setOpening}/>
    </div>
}