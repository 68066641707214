import { useNavigate } from "react-router-dom"
import { deletePost } from "./admin-connect";

export default function DeletionConfirm({ deletionConfirm, setDeletionConfirm, _id }) {
    const navigate = useNavigate();

    return <div className={ ("confirm") + (deletionConfirm ? '' : ' closed') }>
        <h3>Вы уверены, что хотите удалить эту статью?</h3>
        <p>Это действие нельзя отменить</p>
        <div>
            <button onClick={() => setDeletionConfirm(false)}>Отменить</button>
            <button onClick={() => deletePost(_id, navigate)}>Удалить</button>
        </div>
    </div>
}