import { useEffect, useState } from "react"
import { getHomepageInfo } from "./admin-connect";
import { useAuth0 } from "@auth0/auth0-react";
import Entrance from "./Entrance";
import Loading from "../media/Loading";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
    const { isAuthenticated } = useAuth0();
    const [unreadMail, setUnreadMail] = useState(undefined);
    // const [count, setCount] = useState(undefined);
    const [posts, setPosts] = useState(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        getHomepageInfo(setPosts, setUnreadMail);
    }, [])

    return !isAuthenticated ? <Entrance/> : 
    <main id="dashboard">
        {
            !posts && !unreadMail ? <Loading/> :
            <table>
                <tbody>
                    <tr>
                        <td className="mail" onClick={() => navigate('/admin/mailbox')}>
                            <div className="holder">
                                { unreadMail && <div className="notification"/> }
                                <h3>{ unreadMail ? 'У вас есть непрочитанные сообщения' : 'У вас нет непрочитанных уведомлений' }</h3>
                            </div>
                        </td>
                        <td className="posts">
                            <button onClick={() => navigate('/admin/editor')}>Новая статья</button>
                            {
                                posts.map(post => {
                                    return <div className="post" key={post._id} onClick={() => navigate(`./news/${post._id}`)}>
                                        <h4>{post.title}</h4>
                                        <p>{post.text.length > 100 ? post.text.substr(0, 100) + '...' : post.text}</p>
                                    </div>
                                })
                            }
                            <button onClick={() => navigate('/admin/news')}>Все новости</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        }
    </main>
}