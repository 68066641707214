import { useNavigate } from "react-router-dom"
import EmailForm from "./elements/email-form";

export default function Pricing() {
    const navigate = useNavigate();

    return <main id="pricing">
        <button className="back" onClick={() => navigate('/')}>{'< Назад'}</button>
        <h1>Прайс-лист</h1>
        <div className="for-table">
            <table>
                <tbody>
                    <tr>
                        <th>Устная консультация</th>
                        <td>До 30 мин.</td>
                        <td>1000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Правовое заключение по вопросам любой сложности</th>
                        <td>От 5 000₽</td>
                    </tr>
                    <tr>
                        <th rowSpan={2}>Правовая экспертиза документов при покупке объектов коммерческой недвижимости</th>
                        <td>—</td>
                        <td>От 15 000₽</td>
                    </tr>
                    <tr>
                        <td>Получение архивных данных (планов приватизации, межевых дел и пр.)</td>
                        <td>+ От 10 000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Составление договоров купли-продажи, аренды, дарения и пр.</th>
                        <td>3000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Отмена судебного приказа</th>
                        <td>2000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Составление претензии</th>
                        <td>5000₽</td>
                    </tr>
                    <tr>
                        <th>Составление и электронная подача искового заявления в суд (арбитражный суд, суды общей юрисдикции)</th>
                        <td>В случае представления в последующем интересов в суде, сумма включается в общие расходы</td>
                        <td>5000-15 000₽</td>
                    </tr>
                    <tr>
                        <th>Представительство в арбитражном суде</th>
                        <td>При сроке до 6 мес.</td>
                        <td>От 40 000₽</td>
                    </tr>
                    <tr>
                        <th>Представительство в суде общей юрисдикции</th>
                        <td>При сроке до 6 мес.</td>
                        <td>От 40 000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Представительство в Ростовском областном суде по административным делам (КАС)</th>
                        <td>50 000₽</td>
                    </tr>
                    <tr>
                        <th rowSpan={2}>Разовое представительство в суде</th>
                        <td>Общая юрисдикция</td>
                        <td>6000₽</td>
                    </tr>
                    <tr>
                        <td>Арбитраж</td>
                        <td>8000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Банкротство физических лиц</th>
                        <td>От 85 000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Сопровождение в заключении договоров купли-продажи земельных участков, находящихся в государственной собственности</th>
                        <td>От 50 000₽</td>
                    </tr>
                    <tr>
                        <th colSpan={2}>Сопровождение в заключении договоров аренды земельных участков (в Минимуществе Ростовской области, Росимуществе Ростовской области)</th>
                        <td>От 30 000₽</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <EmailForm/>
    </main>
}