import { useNavigate } from "react-router-dom"
import Loading from "../media/Loading";
import { useState } from "react";
import DeletionConfirm from "./deletion-confirm";

export default function FullPost({ post, setPost }) {
    const navigate = useNavigate();
    const [deletionConfirm, setDeletionConfirm] = useState(false);

    function back() {
        setPost();
        setDeletionConfirm(false);
        navigate('/admin/news');
    }

    return <div className={("bg") + (post ? '' : ' closed')}>
        <section className="window">
            <div className="heading">
                <button className="back" onClick={back}>{'< Назад'}</button>
                <p className="date"></p>
            </div>
            <div className="body">
                {
                    post ? <>
                        <h1>{post.title}</h1>
                        {
                            post.text.split('\n').map((paragraph, index) => {
                                return <p key={index}>{paragraph}</p>
                            })
                        }
                    </> : <Loading/>
                }
            </div>
            <div className="footer">
                <button className="edit" onClick={() => navigate(`/admin/editor/${post._id}`)}>Редактировать</button>
                <div className="for-confirmation">
                    <DeletionConfirm deletionConfirm={deletionConfirm} setDeletionConfirm={setDeletionConfirm} _id={post ? post._id : ''}/>
                    <button className="delete" onClick={() => setDeletionConfirm(true)}>Удалить статью</button>
                </div>
            </div>
        </section>
    </div>
}