import { useEffect, useState } from "react";
import DateObject from "react-date-object";
import { useNavigate, useParams } from "react-router-dom";
import { adminPost, editPost, sendPost } from "./admin-connect";
import { useAuth0 } from "@auth0/auth0-react";
import Entrance from "./Entrance";

export default function Editor() {
    const { isAuthenticated } = useAuth0();
    const params = useParams();
    const navigate = useNavigate();
    const [editorMode, setEditorMode] = useState(false);
    const [date, setDate] = useState({
        minute: new Date().getMinutes(),
        hour: new Date().getHours(),
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    });
    const [post, setPost] = useState({
        title: '',
        text: '',
        date: new Date(date.year, date.month, date.day, date.hour, date.minute),
    })
    const [sendLater, setSendLater] = useState(false);

    useEffect(() => {
        if (params._id && isAuthenticated) {
            adminPost(setPost, params._id);
            setEditorMode(true);
        }
    }, [params._id, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            setPost({ ...post, date: new Date(date.year, date.month, date.day, date.hour, date.minute) });
        }
    }, [date, isAuthenticated])

    function prepareForSubmit() {
        if (isAuthenticated) {
            console.log(post);
            sendPost(post, setPost, navigate);
        }
    }
    function prepareForEdit() {
        if (isAuthenticated) {
            editPost(post, setEditorMode);
            navigate(`/admin/news/${post._id}`);
        }
    }

    return isAuthenticated ? <main id="edit">
        <button className="back" onClick={() => navigate('/admin')}>{'< Назад'}</button>
        <form>
            <input className="title" placeholder="Заголовок статьи" required type="text" value={post.title} onChange={event => setPost({...post, title: event.target.value})}/>
            <textarea className="text" placeholder="Текст" required value={post.text} onChange={event => setPost({...post, text: event.target.value})}/>
            {
                !editorMode && <label>
                    <input type="checkbox" value={sendLater} onChange={() => setSendLater(!sendLater)}/>
                    Отправить позже
                </label>

            }
            {
                sendLater && <div className="date">
                    <h4>Дата и время отправки поста:</h4>
                    <input className="hour" type="number" min={0} max={23} value={date.hour} onChange={event => setDate({...date, hour: event.target.value})}/>
                    :
                    <input className="min" type="number" min={0} max={59} value={date.minute < 10 ? '0'+date.minute : date.minute} onChange={event => setDate({...date, minute: event.target.value})}/>
                    <input className="day" type="number" min={1} max={31} value={date.day} onChange={event => setDate({...date, day: event.target.value})}/>
                    <select className="month" value={date.month} onChange={event => setDate({...date, month: event.target.value})}>;
                        <option value={'0'}>Января</option>
                        <option value={'1'}>Февраля</option>
                        <option value={'2'}>Марта</option>
                        <option value={'3'}>Апреля</option>
                        <option value={'4'}>Мая</option>
                        <option value={'5'}>Июня</option>
                        <option value={'6'}>Июля</option>
                        <option value={'7'}>Августа</option>
                        <option value={'8'}>Сентября</option>
                        <option value={'9'}>Октября</option>
                        <option value={'10'}>Ноября</option>
                        <option value={'11'}>Декабря</option>
                    </select>
                    <input className="year" type="number" min={Number(new DateObject().format('YYYY'))} value={date.year} onChange={event => setDate({...date, year: event.target.value})}/>
                </div>
            }
            <button className="send" onClick={event => {
                event.preventDefault();
                if (editorMode) {
                    prepareForEdit();
                } else {
                    prepareForSubmit();
                }
                }}>{sendLater ? 'Запланировать отправку' : 'Опубликовать'}</button>
        </form>
    </main> : <Entrance/>
}