import { useLocation, useNavigate, useParams } from "react-router-dom";
import { data } from "./elements/articles";
import EmailForm from "./elements/email-form";
import { useEffect } from "react";

export default function Article() {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {title, text, innerTitle, picture, tableOfContents} = data.filter(item => item.title === params.title)[0];
    console.log(location.hash);

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
                if (elem) {
                    elem.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
    }, [location]);

    return <main id="article">
        <button className="back" onClick={() => navigate('/')}>{'< Назад'}</button>
        <section className="heading">
            <img className="not-for-mobile" src={picture} alt=""/>
            <h1 className={innerTitle ? innerTitle.length > 25 ? 'long' : title > 25 ? 'long' : '' : ''}>{(innerTitle || title)}</h1>
        </section>
        {
            tableOfContents && <section className="table-of-contents">
                <ol>
                    {
                        tableOfContents.map((item, index) => {
                            return <li onClick={() => {
                                navigate(`#${item.reference}`);
                            }} key={index}>{item.name}</li>
                        })
                    }
                </ol>
            </section>
        }
        {text}
        <EmailForm/>
    </main>
}