import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./media/Loading";
import DateObject from "react-date-object";
import { getPost, getPosts, searchPosts } from "./connect";
import FullPost from "./elements/full-post";
import magnifier from './media/search.png';

export default function News() {
    const [pageNo, setPageNo] = useState(1);
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState();
    const [search, setSearch] = useState('');
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setPosts([]);
        getPosts(setPosts, pageNo);
    }, [pageNo])

    useEffect(() => {
        if (params._id) {
            getPost(setPost, params._id);
        }
    }, [params._id])

    function doSearch(event) {
        event.preventDefault();
        if (search !== '') {
            setPosts([]);
            searchPosts(search, setPosts);
        } else {
            getPosts(setPosts, pageNo);
        }
    }

    return <main id="news">
        <button className="back" onClick={() => navigate('/')}>{'< Назад'}</button>
        <h1>Последние новости</h1>
        <form className="search" onSubmit={doSearch}>
            <input placeholder="Поиск по статьям" type="text" value={search} onChange={event => setSearch(event.target.value)}/>
            <button type="submit" onClick={doSearch}>
                <img src={magnifier} alt="Поиск"/>
            </button>
        </form>
        <div className="list">
            {
                posts.length ? posts.map(post => {
                    return <section className="post" key={post._id} onClick={() => navigate(`./${post._id}`)}>
                        <h3>{post.title}</h3>
                        <p>{post.text.length > 100 ? post.text.substr(0, 100)+'...' : post.text}</p>
                        <p className="date">{new DateObject(post.date).format('DD.MM.YYYY')}</p>
                    </section>
                }) : <Loading/>
            }
        </div>
        {
            !(pageNo === 1 && posts.length < 10) &&
            <div className="page-selector">
                <button onClick={() => {
                    if (pageNo > 1) {
                        setPageNo(pageNo - 1);
                    }
                }}>{ '<' }</button>
                <p>{ pageNo }</p>
                { posts.length >= 10 && <button onClick={() => setPageNo(pageNo + 1)}>{'>'}</button> }
            </div>
        }
        <FullPost post={post} setPost={setPost}/>
    </main>
}