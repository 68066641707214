import { useLocation, useNavigate } from "react-router-dom"
import logo from '../media/icon1.jpg'
import { useAuth0 } from "@auth0/auth0-react";

export default function AdminHeader() {
    const navigate = useNavigate();
    const { isAuthenticated, logout } = useAuth0();
    const { pathname } = useLocation();

    function handleQuit() {
        navigate('/');
    }

    return pathname.includes('/admin') && <header>
        <button className='home' onClick={() => navigate('/admin')}>
            <img src={logo} alt='Логотип'/>
            <h1>Панель Управления</h1>
        </button>
        { isAuthenticated && <button className="quit" onClick={ handleQuit }>Выйти из панели управления</button> }
    </header>
}