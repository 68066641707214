import { useNavigate } from "react-router-dom"

export default function NotFound() {
    const navigate = useNavigate();

    return <main>
        <h1>Ой</h1>
        <p>кажется, страница, которую вы искали, не существует</p>
        <button onClick={() => navigate('/')}>Вернуться на главную</button>
    </main>
}