import axios from "axios"

// https://borisova-jurist-2-0-backend.onrender.com

export const getHomepageInfo = async (setPosts, setUnreadMail, setCount) => {
    await axios.get('https://borisova-jurist-2-0-backend.onrender.com/admin')
    .then(({ data }) => {
        setPosts(data);
    })

    await axios.get('https://borisova-jurist-2-0-backend.onrender.com/admin/mailbox/unread')
    .then(({ data }) => {
        setUnreadMail(data);
    })

    // await axios.get('https://borisova-jurist-2-0-backend.onrender.com/admin/mailbox/count')
    // .then(({ data }) => {
    //     setCount(data);
    // })
}
export async function adminPosts(setPosts, pageNo) {
    await axios.get(`https://borisova-jurist-2-0-backend.onrender.com/user/news/${pageNo}`)
    .then(({ data }) => {
        setPosts(data);
    })
}
export const adminPost = async (setPost = Function, _id = String) => {
    await axios.get(`https://borisova-jurist-2-0-backend.onrender.com/admin/news/articles/${_id}`)
    .then(({ data }) => {
        setPost(data);
    })
}
export const sendPost = async (post, setPost) => {
    await axios.post('https://borisova-jurist-2-0-backend.onrender.com/admin/news', post)
    .then(() => {
        setPost();
    })
}
export const editPost = async (post, setEditorMode, navigate) => {
    await axios.put(`https://borisova-jurist-2-0-backend.onrender.com/admin/news/articles/${post._id}`, post)
    .then(() => {
        setEditorMode(false);
        navigate(`/admin/news/${post._id}`);
    })
}
export const deletePost = async (_id, navigate) => {
    await axios.delete(`https://borisova-jurist-2-0-backend.onrender.com/admin/news/articles/${_id}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            checkupMessage: `Я подтверждаю удаление поста под айди ${_id}`,
        },
    })
    .then(() => {
        navigate('/admin');
    })
}

export async function getMail(setMailbox) {
    await axios.get('https://borisova-jurist-2-0-backend.onrender.com/admin/mailbox')
    .then(({ data }) => {
        setMailbox(data);
    })
}

export async function getExactMail(_id, setMail) {
    await axios.get(`https://borisova-jurist-2-0-backend.onrender.com/admin/mailbox/${_id}`)
    .then(({ data }) => {
        setMail(data);
        axios.put(`https://borisova-jurist-2-0-backend.onrender.com/admin/mailbox/${_id}`, {});
    })
}

export async function deleteMail(_id, navigate, setOpening) {
    console.log('Запрос принят')
    await axios.delete(`https://borisova-jurist-2-0-backend.onrender.com/admin/mailbox/${_id}`, {
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "checkupMessage": `Я подтверждаю удаление письма под айди ${_id}`,
        }
    })
    .then(() => {
        setOpening(false);
        navigate('/admin/mailbox');
    })
}