import { useNavigate } from "react-router-dom";
import Loading from "../media/Loading";
import DateObject from "react-date-object";
import { useEffect } from "react";

export default function FullPost({ post, setPost }) {
    const navigate = useNavigate();

    function back() {
        setPost();
        navigate('/news');
    }

    useEffect(() => {
        document.querySelector('.window .body').scrollTo(0, 0);
    }, [post])

    return <div className={('bg') + (post ? '' : ' closed')}>
        <section className="window">
            <div className="heading">
                <button onClick={back}>{'< Назад'}</button>
                {
                    post && <p className="date">{new DateObject(post.date).format('DD.MM.YYYY')}</p>
                }
            </div>
            <div className="body">
                {
                    post && post.text ? <>
                        <h1>{post.title}</h1>
                        {
                            post.text.split('\n').map((paragraph, index) => {
                                return <p key={index}>{paragraph}</p>
                            })
                        }
                    </> : <Loading/>
                }
            </div>
        </section>
    </div>
}