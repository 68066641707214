import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteMail, getExactMail } from "./admin-connect";
import Loading from "../media/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import Entrance from "./Entrance";

export default function FullMail() {
    const { isAuthenticated } = useAuth0();
    const [mail, setMail] = useState();
    const [isOpened, setOpening] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            getExactMail(params._id, setMail);
        }
    }, [params._id, isAuthenticated])

    useEffect(() => {
        if (!isOpened) {
            setIdToDelete('');
        }
    }, [isOpened])

    return isAuthenticated ? mail ? <main id="mail">
        <button onClick={() => navigate('/admin/mailbox')} className="back">{'< Назад'}</button>
        <section className="letter">
            <p className="letter-id">ID: {mail._id}</p>
            <h1>{mail.sender}</h1>
            {mail.text.split('\n').map((paragraph, index) => {
                return <p key={index}>{paragraph}</p>
            })}
        </section>
        <button onClick={event => {
            event.preventDefault();
            window.location.replace(`mailto: ${mail.sender}`);
        }}>Ответить</button>
        <button onClick={() => setOpening(!isOpened)}>Удалить</button>
        {
            isOpened && <div className="confirm">
                <p>Если вы решили удалить это письмо, пожалуйста, скопируйте и вставьте айди этого письма</p>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (idToDelete === mail._id) {
                        deleteMail(mail._id, navigate, setOpening);
                    }
                }}>
                    <input type="text" value={idToDelete} onChange={event => setIdToDelete(event.target.value)}/>
                    <button type="submit" onClick={event => {
                        event.preventDefault();
                        if (idToDelete === mail._id) {
                            deleteMail(mail._id, navigate, setOpening);
                        }
                    }}>Удалить окончательно</button>
                </form>
            </div>
        }
    </main> : <Loading/> : <Entrance/>
}