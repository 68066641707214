import { useEffect, useState } from "react";
import Loading from "./media/Loading";
import { getHomePagePosts } from "./connect";
import { useNavigate } from "react-router-dom";
import DateObject from "react-date-object";
import EmailForm from "./elements/email-form";
import { data } from "./elements/articles";
import support from './media/icon9.png';
import accompany from './media/icon8.png';
import represent from './media/icon7.png';
import interact from './media/icon6.png';
import { actualResults, results } from "./elements/results";
import checkMark from './media/check-mark.png';
// import DataMessage from "./elements/data-usage";


export default function Home() {
    const [posts, setPosts] = useState([]);
    // const [doc, setDoc] = useState();
    // const [isOpened, setOpening] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getHomePagePosts(setPosts);
    }, [])

    return <main id="home">
        <section className="slogan">
            <h2>Больше, чем просто <span>Юрист</span></h2>
            <div className="line"/>
            <h3>О законе – просто и понятно</h3>
        </section>
        <section className="news">
            <h3>Последние новости в блоге</h3>
            <div className="list">
                {
                    posts.length ? 
                    posts.map(post => {
                        return <div className="post" key={post._id} onClick={() => navigate(`/news/${post._id}`)}>
                            <h4>{post.title}</h4>
                            <p>{post.text.length > 100 ? post.text.substr(0, 100) + '...' : post.text}</p>
                            <p className="date">{new DateObject(post.date).format('DD.MM.YYYY')}</p>
                        </div>
                    }) : <Loading/>
                }
                {
                    posts.length ?
                    <div className="post more" onClick={() => navigate('/news')}>
                        <p>{'Больше новостей ->'}</p>
                    </div> : <></>
                }
            </div>
        </section>
        <section className="pricing">
            <button onClick={() => navigate('/pricing')}>Узнайте стоимость услуг</button>
        </section>
        <section className="we-do">
            <div className="item">
                <img src={support} alt=""/>
                <h4>Защищаем права</h4>
                <p>граждан, предпринимателей и коммерческих организаций во всех федеральных округах Российской Федерации</p>
            </div>
            <div className="item">
                <img src={accompany} alt=""/>
                <h4>Сопровождаем споры</h4>
                <p>по всем категориям гражданских споров, включая наследственные, земельные, семейные споры, споры о неосновательном обогащении</p>
            </div>
            <div className="item">
                <img src={represent} alt=""/>
                <h4>Представляем интересы</h4>
                <p>в арбитражных судах, судах общей юрисдикции по всей России.</p>
            </div>
            <div className="item">
                <img src={interact} alt=""/>
                <h4>Взаимодействуем</h4>
                <p>с органами государственной власти при разрешении вопросов, вытекающих из ведения коммерческой деятельности</p>
            </div>
        </section>
        <section className="reasons">
            <h3>{data.length} случаев, когда к нам точно стоит обратиться</h3>
            <section className="list">
                {
                    data.map(post => {
                        return <div className="card" key={post.title} onClick={() => navigate(`/articles/${post.title}`)}>
                            <h4>{post.title}</h4>
                            <p>Подробнее</p>
                            <img src={post.picture} alt=""/>
                        </div>
                    })
                }
            </section>
        </section>
        <section className="result">
            <section className="overall">
                <h2>Результативность <span>из года в год</span></h2>
                <div className="list">
                    {
                        results.map((item, index) => {
                            return <div className="item" key={index}>
                                <img src={checkMark} alt=""/>
                                <p>{item}</p>
                            </div>
                        })
                    }
                </div>
                <h2><span>Наши результаты легко проверить:</span></h2>
                <div className="card-list">
                    {
                        actualResults.map(item => {
                            return <div className="card" key={item.name} onClick={() => window.open(item.file)}>
                                <p className="name">{item.name}</p>
                                { item.description && <p className="description">{item.description}</p> }
                                { item.asking && <p className="asked">Взыскивали: {item.asking} руб.</p> }
                                { item.took && <p className="took">Взыскали: {item.took} руб.</p> }
                                { item.economy && <p className="economy">Экономия: {item.economy} руб.</p> }
                            </div>
                        })
                    }
                </div>
            </section>
        </section>
        <section className="con">
            <EmailForm/>
            {/* <button>Связаться</button> */}
        </section>
        {/* <DataMessage isOpened={isOpened} setOpening={setOpening} doc={doc}/> */}
        <section className="artemkhi">
            <p>Site by <span onClick={() => window.open('https://artemkhi.me/')}>artemkhi</span></p>
        </section>
    </main>
}